<template>
  <div>
    <div class="footer">
      <div class="fotter_logo">
        <div>
          <img src="@/assets/img/index/logo3.png" alt="" class="logo3" />
          <div class="slogn">客户为根、以人为本、专业为上、服务为先</div>
        </div>

        <div class="fotter_logo_right">
          <div class="left_ows">
            <div class="left_address">
              <div>
                公司地址：湖南省长沙市天心区劳动西路348号贺龙体育场东门5040-5051房
              </div>
            </div>
            <div class="left_a">
              <div v-for="(item, i) in ows_a_list" :key="i" class="ows_txt">
                <a
                  :title="item.title"
                  target="_black"
                  :href="item.url"
                  class="ows_txt_a"
                  >{{ item.title }}</a
                >
              </div>
            </div>
          </div>
          <div>
            <div class="gzh_img">
              <img
                src="@/assets/img/index/gzh.png"
                alt=""
                style="width: 100%; height: 100%"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="beian">
        © {{ new Date().getFullYear() }} 湖南大湘人力资源集团有限公司 版权所有

        <!-- <span style="margin-left: 10px">
          <img
            src="@/assets/img/index/gongan.png"
            alt=""
            style="width: 15px; height: 15px"
          />
        </span>
        湘公网安备 330100xxxx70号 -->

        <a v-if="icp_status=='dxrc'" href="https://beian.miit.gov.cn/" target="_black"> 湘ICP备12004962号-13</a>
        <a v-if="icp_status=='daxiang_group'" href="https://beian.miit.gov.cn/" target="_black"> 湘ICP备2023037246号-1</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      ows_a_list: [
        { title: "大湘人才网", url: "https://www.dxrc.com" },
        { title: "长沙招聘网", url: "https://www.0731job.com" },
        { title: "大象系统", url: "https://www.daxiangsys.com" },
      ],
      icp_status:0
    };
  },
  mounted(){
    const domainName = window.location.hostname;
    if (['www.dxrc.com.cn','dxrc.com.cn'].indexOf(domainName)!=-1){
      this.icp_status = 'dxrc'
    }
    if (['www.daxiang.group','daxiang.group'].indexOf(domainName)!=-1){
      this.icp_status = 'daxiang_group'
    }

  }
};
</script>

<style scoped>
.left_a {
  display: flex;
}

.left_address {
  font-family: "dingding04";
  text-align: left;
  color: #ccc;
  margin-bottom: 10px;
  margin-top: 10px;
}

.ows_txt {
  margin-bottom: 10px;
  color: #ccc;
  font-family: "dingding04";

  margin-right: 20px;
}

.left_ows {
  margin-right: 40px;
  font-size: 14px;
  height: 100%;
  width: 300px;
  align-items: flex-start;
}

a:hover {
  color: #0466dd;
}

.slogn {
  font-family: "dingding02";
  font-size: 18px;
  color: #dcdcdc;
  padding-left: 20px;
}

a {
  text-decoration: none;
  color: inherit;
}
.fotter_logo_right {
  display: flex;
  align-items: center;
}
.beian {
  margin-top: 30px;
  font-size: 13px;
  color: #ccc;
  padding-top: 30px;
  border-top: 1px solid #e3e3e3;
}

.pagemain {
  height: 100vh;
  overflow: auto;
}

.head_menu_div {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 100px;
}

.fotter_logo_right {
  display: flex;
  align-items: center;
}

.guanzhu {
  font-size: 22px;
  color: #ccc;
}

.gzh_img {
  padding: 3px;
  background: #fff;
  border-radius: 5px;
  width: 100px;
  height: 100px;
}

.logo3 {
  height: 65px;
  padding: 10px;
}
.fotter_logo {
  display: flex;
  padding: 0 5%;
  justify-content: space-between;
}
.footer {
  padding: 50px 0 30px 0;
  background: #f6f6f6;
}
</style>

<template>
  <div ref="scrollContainer" class="pagemain" @scroll="handleScroll">
    <Head v-show="scrolled < 200" class="" ref="head" :menu_index="2"></Head>
    <div class="about_us_bg">
      <img src="@/assets/img/index/honor.png" alt="" style="width: 100%; height: 100%" />
    </div>
    <div style="height: 10px"></div>
    <div class="content">
      <div class="news_title2">
        <div class="dito"></div>
        企业荣誉
      </div>

      <div class="content_inner2">
        <div class="content_inner3">
          <div v-for="(item, i) in honor_list" :key="i" class="honor_name">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <div style="height: 10px"></div>
    <div class="content">
      <div class="news_title2">
        <div class="dito"></div>
        合作客户
      </div>

      <div class="content_inner">
        <img src="@/assets/img/index/honor2.png" alt="" />
      </div>
    </div>

    <div style="height: 10px"></div>
    <div class="content">
      <div class="news_title2">
        <div class="dito"></div>
        荣誉证书
      </div>

      <div class="content_inner">
        <div>
          <img class="honor_img" src="@/assets/img/index/honor3.png" alt="">
          <img class="honor_img" src="@/assets/img/index/honor4.png" alt="">
        </div>
        <img src="@/assets/img/index/honor1.png" alt="" />
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import { request, open_new_page } from "../../utils/http";
import Head from "../common/head.vue";
import Footer from "../common/footer.vue";

export default {
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      scrolled: 0,

      honor_list: [
        "中国人力资源服务机构100强",
        "湖南省人力资源服务协会轮值会长单位",
        "湖南省毕业生就业促进会副会长单位",
        "湖南省人力资源服务领军人才企业",
        "湖南省人力资源诚信服务示范机构",
        "湖南省创新创业带动就业示范单位",
        "湖南省AAA级诚信人力资源服务机构",
        "湖南省人力资源优质服务品牌",
        "湖南省守合同重信用企业",
        "湖南省知名商标品牌",
        "长沙市人力资源服务协会副会长单位",
        "长沙市人力资源服务机构信用等级AAA级",
        "长沙市服务重点企业促进就业示范机构",
        "长沙市人力资源诚信服务示范机构",
        "长沙市优质人力资源服务机构",
      ],
    };
  },
  mounted() {},
  methods: {
    handleScroll(e) {
      let scrolled = this.$refs.scrollContainer.scrollTop;
      // console.log(scrolled);
      this.scrolled = scrolled;
    },
  },
};
</script>

<style scoped>
.dito {
  height: 20px;
  width: 10px;
  background: #dd0404;
  border-radius: 3px;
  margin-right: 10px;
}

.honor_img{
  height: 300px;
  margin:10px;
}


.news_title2 {
  display: flex;
  align-items: center;
  padding-left: 10%;
  padding-bottom: 20px;
  justify-content: flex-start;
  font-size: 24px;
  color: #333;
}
.content_txt_inner_txt {
  position: relative;
  text-indent: 0;
  top: -20px;
  color: #ccc;
  font-weight: 300;
  font-size: 20px;
}

.content_txt {
  font-size: 17px;
  color: #333;
  text-indent: 2em;
  font-weight: 300;
  text-align: left;
  margin: 20px 0;
  line-height: 40px;
  padding: 40px 15% 20px 15%;
}

.content_txt2 {
  padding: 40px 15% 20px 15%;
  background: #fff;
  font-size: 17px;
  color: #333;
  text-indent: 2em;
  font-weight: 300;
  text-align: left;
  margin: 20px 0;
  line-height: 40px;
}

.honor_name {
  font-size: 16px;
  letter-spacing: 3px;
  font-weight: 600;
  padding-bottom: 5px;
  text-align: left;
  padding-bottom: 5px;
  padding-left: 10px;
  margin-bottom: 5px;
  border-bottom: 1px solid #eaeaea;
}

.content_inner2 {
  display: flex;
  justify-content: center;
}

.content {
  background: #fff;
  padding: 20px;
}

.content_inner3 {
  width: 370px;
}

.menu_txt {
  height: 40px;
  padding-bottom: 10px;
  border-bottom: 6px solid orangered;
  color: #333;
  font-weight: 600;
}

.menu_txt2 {
  height: 40px;
  color: #c0c0c0;
}

.menu_txt2:hover {
  color: #888;
}

.menu_div {
  padding: 20px 10%;
  display: flex;
  background: #fff;
  align-items: center;
}



.pagemain {
  height: 100vh;
  background: #f4f4f4;
  overflow: auto;
}

.menu_title {
  font-size: 18px;

  cursor: pointer;
  margin-right: 30px;
}

.menu_title {
  font-size: 18px;
  color: #333;
  margin-right: 30px;
}

.about_us_bg {
}
</style>

<template>
  <div ref="scrollContainer" class="pagemain" @scroll="handleScroll">
    <Head v-show="scrolled < 200" class="" ref="head" :menu_index="3"></Head>
    <div class="about_us_bg">
      <img
        src="@/assets/img/index/about_us.png"
        alt=""
        style="width: 100%; height: 100%"
      />
    </div>
    
    <div class="menu_div">
      <div
        class="menu_title"
        v-for="(item, i) in menu_list"
        :key="i"
        @click="menu_index = i"
      >
        <a :class="i == menu_index ? 'menu_txt' : 'menu_txt2'" :href="item.id">
          {{ item.title }}
        </a>
      </div>
    </div>
    <div class="content">
      <div class="content_txt" id="a1">
        <div class="content_txt_inner_txt"># 企业简介</div>
        大湘人力资源集团创立于2011年，12年来专注于人才和就业服务。 <br>
        <span style="font-weight:600">
            我们围绕国家“就业优先”战略、“人才强国”战略，锚定湖南“三高四新”美好蓝图和长沙全力建设全球研发中心城市目标，以“促进就业、赋能人才、服务产业”为公司战略定位和使命任务。
        </span>
        
      </div>
      <div class="content_txt2" id="a2">
        <div class="content_txt_inner_txt"># 业务介绍</div>
        公司以“招聘+外包”为核心业务，服务覆盖中高端人才猎聘、批量招聘、劳务派遣、外包服务、灵活用工、技能培训、企业培训、企业管理咨询、企业出海服务、数字化等人力资源全盘需求，以高质量人力资源服务助力经济社会高质量发展。
      </div>
      <div class="content_txt2" id="a3">
        <div class="content_txt_inner_txt"># 旗下公司</div>
        大湘人力资源集团旗下有潇湘人力、大湘人才、大湘速聘、大湘机电、大湘教育培训、大湘管理咨询等20多家公司主体。<br> 集团总部位于湖南长沙，在长沙贺龙体育中心、长沙湘江新区、长沙经开区设有四大运营中心，在长沙、株洲、湘潭等地均设立了分子公司，在湖南各地市州以及华东、华中、华南等多地设立了分支机构，业务立足长沙、服务全省、辐射全国。<br> 目前集团有员工近300人，年营收过10亿元，年纳税近5000万元。我们与上百家大型知名企业建立了长期战略合作伙伴关系，累计服务客户近万家，年服务触达百万人次，年输送就业十万余人次，每年开展各类线上线下培训上万人次。
      </div>
      <div class="content_txt" id="a4">
        <div class="content_txt_inner_txt"># 核心价值观</div>
        多年来，大湘秉承“客户为根、以人为本、专业为上、服务为先”的核心价值观，规范管理、诚信经营，获得了客户、员工、政府和行业的多方好评，现已成为了引领湖南、影响全国的大型专业人力资源服务机构。<br> 2023年，大湘人力资源集团被评为中国人力资源服务机构100强，公司先后获评湖南省创新创业带动就业示范单位、湖南省人力资源诚信服务示范机构、长沙市人力资源诚信服务示范机构，当选湖南省人力资源服务协会副会长单位、长沙市人力资源服务协会副会长单位，为高质量充分就业和经济社会高质量发展持续贡献大湘力量！
      </div>
      <div class="content_txt2" id="a5">
        <div class="content_txt_inner_txt"># 团队风采</div>
        目前公司有员工近300人为百万会员和千家客户提供专业服务。<br> 其中多人持有国家高级人力资源管理师证书国家二级或三级人力资源管理师证书，另有劳动关系协调师职业指导师企业培训师、经济师、会计师、律师、教师等专业人才若干。
        <div class="teamimg">
          <img
            style="border-radius: 10px; overflow: hidden"
            src="@/assets/img/index/team1.png"
            alt=""
          />
        </div>
      </div>
      <div></div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { request, open_new_page } from "../../utils/http";
import Head from "../common/head.vue";
import Footer from "../common/footer.vue";

export default {
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      scrolled: 0,

      menu_list: [
        { title: "企业简介", id: "#a1" },
        { title: "业务介绍", id: "#a2" },
        { title: "旗下公司", id: "#a3" },
        { title: "核心价值观", id: "#a4" },
        { title: "团队风采", id: "#a5" },
      ],
      menu_index: 0,
    };
  },
  mounted() {},
  methods: {
    handleScroll(e) {
      let scrolled = this.$refs.scrollContainer.scrollTop;
      // console.log(scrolled);
      this.scrolled = scrolled;
    },
  },
};
</script>

<style scoped>
.teamimg {
  display: flex;
  justify-content: center;
  margin-top:30px;
}

.content_txt_inner_txt {
  position: relative;
  text-indent: 0;
  top: -20px;
  color: rgb(240, 97, 9);
  font-weight: 300;
  font-size: 20px;
}

.content_txt {
  font-size: 17px;
  color: #333;
  text-indent: 2em;
  font-weight: 300;
  text-align: left;
  margin: 20px 0;
  line-height: 40px;
  padding: 40px 15% 20px 15%;
}

.content_txt2 {
  padding: 40px 15% 20px 15%;
  background: #fff;
  font-size: 17px;
  color: #333;
  text-indent: 2em;
  font-weight: 300;
  text-align: left;
  line-height: 40px;
}

.content {
}

.menu_txt {
  height: 40px;
  padding-bottom: 10px;
  border-bottom: 6px solid orangered;
  color: #333;
  font-weight: 600;
}

.menu_txt2 {
  height: 40px;
  color: #c0c0c0;
}

.menu_txt2:hover {
  color: #888;
}

.menu_div {
  padding: 20px 10% 30px 10%;
  display: flex;
  background: #fff;
  align-items: center;
}

.pagemain {
  height: 100vh;
  background: #f4f4f4;
  overflow: auto;
}

.menu_title {
  font-size: 18px;

  cursor: pointer;
  margin-right: 30px;
}

.menu_title {
  font-size: 18px;
  color: #333;
  margin-right: 30px;
}

.about_us_bg {
  height: 300px;
}
</style>
